import React, { useState } from "react";
import { Image } from "@nextui-org/react";
import { router } from "@inertiajs/react";
import { Card, Link, Typography } from "@mui/material";

declare global {
    interface Window {
        Chatra: any;
    }
}

const Footer: React.FC = () => {
    const [isEmailFormSubmitted, setEmailFormSubmitted] = useState(false);

    const handleEmailFormSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setEmailFormSubmitted(true); // Устанавливаем состояние как отправлено
    };
    const openChatra = () => {
        if (window.Chatra) {
            window.Chatra('openChat', true);
        } else {
            console.error('Chatra is not loaded yet.');
        }
    };

    const openTelegram = () => {
        window.open("https://t.me/discoexchange", "_blank");
    };

    const scrollToExchange = () => {
        if (window.location.pathname === "/") {
            document.getElementById("exchange")?.scrollIntoView({ behavior: "smooth" });
        } else {
            router.visit("/#exchange");
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    return (
        <>
            <section className="pt-10 pb-8 overflow-hidden sm:pt-16 lg:pt-24">
                <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                    <div className="max-w-2xl mx-auto text-center">
                        <Typography sx={{ mb: 4 }} color="textPrimary" variant="h6">Соединитесь со всеми приложениями</Typography>
                        <Link
                            href="/dashboard"
                            title=""
                            className="inline-flex items-center justify-center px-8 py-4 text-base font-semibold transition-all duration-200 border-2 border-green-400 rounded-md mt-9 hover:bg-green-400 hover:text-black hover:border-green-400 focus:bg-green-400 focus:text-black focus:border-green-400"
                            role="button"
                        >
                            Мой Кабинет
                        </Link>
                    </div>
                </div>

                <img className="w-full min-w-full mx-auto mt-12 scale-150 max-w-7xl lg:min-w-0 lg:mt-0 lg:scale-100" src="https://cdn.rareblocks.xyz/collection/celebration/images/integration/1/services-icons.png" alt="" />
            </section>

            <Card variant="outlined" sx={{ p: 2, backgroundColor: 'background.paper' }}>
                <div className="mx-auto max-w-screen-xl text-center">
                    <a href="/" className="flex justify-center items-center text-2xl font-semibold">
                        <Image src="/assets/img/bx.png" className="w-12 mr-2" />
                        Bixcoin Exchange
                    </a>
                    <p className="my-6 text-gray-500 dark:text-gray-400">Самый быстрый и качественный среди конкурентов</p>
                    <ul className="flex flex-wrap justify-center items-center mb-6">
                        <li>
                            <a href="#" className="mr-4 hover:underline md:mr-6 ">Обмен</a>
                        </li>
                        <li>
                            <a href="#" className="mr-4 hover:underline md:mr-6">Вопрос-ответ</a>
                        </li>
                        <li>
                            <a href="#" className="mr-4 hover:underline md:mr-6 ">Резервы</a>
                        </li>
                        <li>
                            <a href="#" className="mr-4 hover:underline md:mr-6">Наш Сервис</a>
                        </li>
                        <li>
                            <a href="/docs/aml" className="mr-4 hover:underline md:mr-6">AML правила</a>
                        </li>
                        <li>
                            <a href="/docs/terms" className="mr-4 hover:underline md:mr-6">Правила сервиса</a>
                        </li>
                        <li>
                            <a href="#" className="mr-4 hover:underline md:mr-6">FAQs</a>
                        </li>
                        <li>
                            <a href="#" className="mr-4 hover:underline md:mr-6">Контакты</a>
                        </li>
                    </ul>
                    <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2023-2024 <a href="/" className="hover:underline">Bixcoin™</a>. Все права защинены.</span>
                </div>
            </Card>

        </>
    );
};

export default Footer;
