import * as React from 'react';
import { AppProvider, Router, NavigationItem } from '@toolpad/core/AppProvider';
import { DashboardLayout } from '@toolpad/core/DashboardLayout';
import { PageContainer } from '@toolpad/core/PageContainer';
import { Box, Typography, styled, createTheme, ThemeProvider } from '@mui/material';
import { usePage, router } from '@inertiajs/react';
import SwapCallsIcon from '@mui/icons-material/SwapCalls';
import ReviewsIcon from '@mui/icons-material/Reviews';
import DataThresholdingIcon from '@mui/icons-material/DataThresholding';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import Footer from '@/Components/Footer';
import HandshakeIcon from '@mui/icons-material/Handshake';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import PriceChangeIcon from '@mui/icons-material/PriceChange';

interface AuthenticatedProps {
    header?: React.ReactNode;
    children?: React.ReactNode;
    user?: {
        name: string;
        email: string;
    };
}


interface AuthenticatedProps {
    header?: React.ReactNode;
    children?: React.ReactNode;
}

const Skeleton = styled('div')<{ height: number }>(({ theme, height }) => ({
    backgroundColor: theme.palette.action.hover,
    borderRadius: theme.shape.borderRadius,
    height,
    content: '" "',
}));

// Создание темы с настройкой активных и неактивных цветов
const customTheme = createTheme({
    cssVariables: {
        colorSchemeSelector: 'data-toolpad-color-scheme',
    },
    colorSchemes: {
        dark: {
            palette: {
                background: {
                    default: '#000000', // Чёрный фон для тёмной темы
                },
                primary: {
                    main: '#4ade80', // Зелёный цвет для активных элементов
                },
                text: {
                    primary: '#FFFFFF', // Белый цвет для текста по умолчанию
                    secondary: '#4ade80', // Зелёный для активного текста
                },
            },
        },
        light: {
            palette: {
                background: {
                    default: '#FFFFFF', // Чёрный фон для тёмной темы
                },
                primary: {
                    main: '#4ade80', // Зелёный цвет для активных элементов
                },
                text: {
                    primary: '#000000', // Белый цвет для текста по умолчанию
                    secondary: '#4ade80', // Зелёный для активного текста
                },
            },
        },
    },
    components: {
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    color: '#FFFFFF', // Белый цвет для всех пунктов меню
                    '&.Mui-selected': {
                        color: '#4ade80', // Зелёный для активного пункта меню
                    },
                },
            },
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 600,
            lg: 1200,
            xl: 1536,
        },
    },
});

export default function Authenticated({ header, children }: AuthenticatedProps) {
    const { auth } = usePage().props as unknown as { auth: { user: { name: string; email: string } } };
    const [pathname, setPathname] = React.useState(() => window.location.pathname);

    const routerConfig: Router = {
        pathname,
        searchParams: new URLSearchParams(),
        navigate: (path: string | URL) => {
            const url = typeof path === 'string' ? path : path.toString();
            if (url.startsWith('/')) {
                router.visit(url); // Используем Inertia для внутренних ссылок
            } else {
                setPathname(url); // Обновляем путь для других случаев
            }
        },
    };

    // Функция для проверки активного состояния
    const isActive = (segment: string) => pathname.includes(segment);

    const NAVIGATION: NavigationItem[] = [
        { kind: 'header', title: 'Основные вкладки' },
        { segment: '', title: 'Обмен', icon: <SwapCallsIcon /> },
        { segment: 'reserve', title: 'Резервы', icon: <DataThresholdingIcon /> },
        { segment: 'reviews', title: 'Отзывы', icon: <ReviewsIcon /> },
        { segment: 'about', title: 'Наш Сервис', icon: <AddBusinessIcon /> },
        { kind: 'divider' },
        { kind: 'header', title: 'Доходность' },
        { segment: 'vacancies', title: 'Партнерская программа', icon: <HandshakeIcon /> },
        { kind: 'divider' },
        { kind: 'header', title: 'Помощь' },
        { segment: 'contacts', title: 'Контакты', icon: <ConnectWithoutContactIcon /> },
        { segment: 'faq', title: 'Вопросы-ответы', icon: <ContactSupportIcon /> },
        { kind: 'divider' },
        { kind: 'header', title: 'Другое' },
        auth.user
            ? { segment: 'dashboard', title: 'Кабинет', icon: <SwapCallsIcon /> }
            : { segment: 'login', title: 'Войти', icon: <VpnKeyIcon /> },
        auth.user
            ? { segment: 'logout', title: 'Выйти', icon: <VpnKeyIcon /> }
            : { segment: 'register', title: 'Регистрация', icon: <HowToRegIcon /> },
    ];

    return (
        <ThemeProvider theme={customTheme}>
            <AppProvider
                branding={{
                    logo: <img src="/assets/img/bx.png" alt="Custom logo" />,
                    title: 'BIXCOIN',
                }}
                navigation={NAVIGATION.map((item) => {
                    if ('segment' in item && item.segment) {
                        return {
                            ...item,
                            active: isActive(item.segment),
                        };
                    }
                    return item;
                })}
                router={routerConfig}
            >
                <DashboardLayout>
                    <PageContainer>
                        <Box sx={{ p: 3 }}>
                            {header && (
                                <Box component="header" sx={{ py: 2, mb: 3 }}>
                                    <Typography color='textPrimary' variant="h4">{header}</Typography>
                                </Box>
                            )}
                            <main>{children} <Footer /></main>
                        </Box>
                    </PageContainer>
                </DashboardLayout>
            </AppProvider>
        </ThemeProvider>
    );
}
